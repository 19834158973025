<template>
  <div>
    <v-row justify="center" class=" pt-1">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <div class="mx-2 heading5 robotocondensed text-left white--text">
           
      <!-- <div class="text-center" v-if="userSnapshot.status === 'PENDING'">
        <v-icon color="secondary" class="vicon-noaccess"
          >mdi-account-clock</v-icon
        >
        <v-divider class="primary mt-4"></v-divider>
        <p class="font-weight-bold text-h5 secondary--text my-5">Account Pending</p>
        <span class="heading6 my-5"
          >Please wait for your account to be Approved by the Principality Stadium Access, check back soon.</span
        >
      </div> -->
            <div class="text-center" v-if="userSnapshot.status === 'SUSPENDED'">
        <v-icon color="secondary" class="vicon-noaccess"
          >icons8-no-access</v-icon
        >
        <v-divider class="primary mt-4"></v-divider>
        <p class="font-weight-bold text-h5 secondary--text my-5">Account Suspended</p>
        <span class="heading6 my-5"
          >Your account has been suspended, please contact administrator.</span
        >
      </div>


        <div v-else>
            <div class="ml-2 heading5 secondary--text">Home</div>
            <div class="ml-2 body1 white--text"> Please select the day you require Access for</div>

              <!-- <v-expansion-panels v-model="importantMessage" class="mt-3 px-1 pb-5">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                  <span class="font-weight-bold">Important please read first</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content >
                  <div class="subtitle1">
                    <p>Once you have applied and filled out the Pre-Event Self Certification, you can use the list below to access your Event Pass.</p>
                    <p><strong>PLEASE NOTE:</strong> DO NOT attend the event if you are not Approved or if you are notified that your application has been Declined.</p>
                </div>                
              </v-expansion-panel-content>
            </v-expansion-panel> 
          </v-expansion-panels> -->
          <v-row class="" no-gutters>
            <v-col class="" cols="12" xs="12" sm="12" md="4" v-for="event in filteredEvents" :key="event.id">
              <v-row class="card ma-1 pointer noselect">
                <v-col cols="12" sm="12" class="text-left" style="border-bottom: 1px solid #343434;">
                  <div class="heading6"  :class="{ 'grey--text' : $moment(event.eventDate, 'x').diff($moment(), 'hours') > 24, 'secondary--text': $moment(event.eventDate, 'x').diff($moment(), 'hours') <= 24 }">{{ event.eventName }}</div>
                </v-col>
                <v-col cols="12" sm="12" class="text-left d-flex justify-space-between align-center">
                  <div class="">
                    <div class="overline" v-if="$moment(event.eventDate, 'x') <= $moment().add(1, 'days').format('x')" :class="{ 'grey--text' : $moment(event.eventDate, 'x').diff($moment(), 'hours') > 24, 'white--text': $moment(event.eventDate, 'x').diff($moment(), 'hours') <= 24  }">
                      <strong @click="rowClick(event, 'preeventcert')"  class="overline white--text" v-if="lookupPass(event.id, 'status') === 'NO PASS'">Apply for Entry Now</strong>
                      <strong @click="rowClick(event, 'checkinevent')" class="overline white--text" v-if="lookupPass(event.id, 'status') === 'APPROVED'">Click to View Pass</strong>
                      <strong @click="rowClick(event, 'checkinevent')" class="overline white--text" v-if="lookupPass(event.id, 'status') === 'DECLINED'">Click for More Info</strong>
                    </div>
                    <div class="overline" v-if="$moment(event.eventDate, 'x') > $moment().add(1, 'days').format('x')" :class="{ 'grey--text' : $moment(event.eventDate, 'x').diff($moment(), 'hours') > 24, 'white--text': $moment(event.eventDate, 'x').diff($moment(), 'hours') <= 24  }">Apply from <strong>{{ formatDatetime($moment(event.eventDate, 'x').subtract(1, 'days'),'x', 'DD MMM YYYY') }}</strong></div>
                  </div>
                  <div class="text-center justify-center" style="min-width: 80px !important;">
                    <div @click="rowClick(event, 'checkinevent')" v-if="lookupPass(event.id, 'status') === 'APPROVED'">
                      <v-icon :style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}" class="iconbackground display-2 success--text icons8-ok"></v-icon>
                      <div class="caption font-weight-bold white--text">APPROVED</div>
                    </div>
                    <div @click="rowClick(event, 'checkinevent')" v-if="lookupPass(event.id, 'status') === 'DECLINED'">
                      <v-icon :style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}" class="iconbackground display-2 error--text icons8-no-entry"></v-icon>
                      <div class="caption font-weight-bold white--text">DECLINED</div>
                    </div>

                    <div @click="rowClick(event, 'preeventcert')" v-if="lookupPass(event.id, 'status') === 'NO PASS'">
                      <v-icon class="display-2 grey--text icons8-form"></v-icon>
                      <div class="caption grey--textfont-weight-bold">APPLY</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- <pre align="left">{{ passes }}</pre> -->
        </div>
        </div>
        
        <br /><br />
        <appfooter />
      </v-col>
    </v-row>
    <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        mode="out-in"
    >
      <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
    </transition>
  </div>
</template>

<script>
// Clases
class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}

export default {
  data(){
    return {
      userSnapshot: {
          status: ''
      },
      importantMessage: null,
      snackbarKey: 0,
      snackbar: {},      
      venues: [],
      events: [],
      passes: [],
    }
  },
  computed: {
  },
  methods: {
    rowClick(event, action) {
      if (this.$moment(event.eventDate, 'x').diff(this.$moment(), 'hours') <= 24) {
        //var action = this.$route.params.action;
        if (action === 'checkinevent') {
          this.$router.push(`/${action}/${this.lookupPass(event.id, 'id')}`);
        } else if (action === 'preeventcert') {
          var passStatus = this.lookupPass(event.id, 'status')
          if ((passStatus !== 'APPROVED') && passStatus !== 'DECLINED') {
            this.$router.push(`/${action}/${this.lookupEvent(event.id, 'id')}`);
          } else {
            this.snackbar = new SNACKBAR(true,'success','Certification has already been Completed',3000); // update snack bar with error
            this.snackbarKey++
          }
        }
      } else {
         this.snackbar = new SNACKBAR(true,'errorSnackbar','Event Not Available Yet',3000); // update snack bar with error
         this.snackbarKey++
      }
    },
  },
  mounted() { 
    this.readDocuments('venues', 'venues'); // get list of events from database
    this.readDocuments('events', 'events'); // get list of events from database
    this.readDocumentsByKeyValue('passes', 'passes', 'userId', this.$store.getters.currUser.userID); // get list of events from database    
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

 this.$firebase.db.collection('users').doc(this.$store.getters.currUser.userID).onSnapshot((doc) => {
        var Data = {}
        // console.log('doc' + doc.data());
        Data = doc.data();
        Data.id = doc.id;
        this.userSnapshot = Data;
    })      
}
}
</script>

<style>

  .card {
    border-radius: 5px;
    min-height: 110px !important;
    background-color:rgba(0, 0, 0, 0.3);
  }
  .iconbackground {
    background-position: center center; 
    background-size: 80%; 
  } 
</style>
