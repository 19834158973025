<template>
  <v-row class="justify-center" no-gutters>
    <v-row class="px-5 pt-4 justify-center">
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="heading6 white--text">Making Events Safer<br /></div>
        <p class="mt-3 text-left body2">
          At the Principality Stadium Access , the wellbeing of our staff, contractors and the general
          public remains our top priority. <br /><br />In-line with Government
          guidelines and Principality Stadium Access  Policy, we require all staff, press, media and
          contractors to carry out a Covid-19 self-assessment at least 24 hours
          prior to visiting a venue.
        </p>
      </v-col>
    </v-row>
    <v-row class="px-5 pt-4 justify-right no-gutters">
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-container class="pa-0 ma-0">
          <div class="heading6 secondary--text text-left">Reset Password</div>
          <div v-if="emailSent === false">
          <p class="mt-3 text-left body2">
            Please enter your email address to request a password reset.
          </p>
          <v-form
            ref="email"
            v-on:submit.prevent="resetPassword"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              ref="email"
              v-model.trim="email"
              label="Email"
              solo
              append-icon="icons8-envelope-3"
              hide-details="auto"
              data-vv-name="email"
              required
              :rules="[rules.required, rules.email]"
              v-on:keyup.enter="resetPassword()"
            ></v-text-field>
          </v-form>
          <p class="mt-4">
            <center>
              <v-btn
                @click="resetPassword()"
                color="secondary"
                class="px-6  white--text"
                >Send reset password link</v-btn
              >
            </center>
          </p>
          </div>
          <div v-else>
                <p class="mt-3 text-left body2">
            A reset password email has been sent out to your account. Please the try again button to resent another email.
          </p>
            <v-row class="mx-1 pt-2">
              <v-btn
                @click="emailSent = false"
                color="secondary"
                class=" px-6  white--text"
                >Try again</v-btn
              >
             <v-spacer></v-spacer>
            <v-btn
                @click="goToLogin"
                color="secondary"
                class="white--text"
                >Login</v-btn>
          </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <!--notification-component-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <appsnackbar
        :status="snackbar.status"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
        :message="snackbar.message"
        :key="snackbarKey"
      />
    </transition>
  </v-row>
</template>

<script>
class SNACKBAR {
  constructor(status, color, message, timeout) {
    this.status = status;
    this.color = color;
    this.message = message;
    this.timeout = timeout;
  }
}
export default {
  name: "ResetPassword",
  data() {
    return {
      valid: true,
        snackbarKey: 0,
            snackbar: {},
            emailSent: false,
      email: "",
      rules: {
        required: (value) => !!value || "This filed is required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail. Pleas try again ";
        },
      },
    };
  },
  computed: {},
  methods: {
    resetPassword: function() {
       
      this.email = this.email.toLowerCase();
      if (this.$refs.email.validate()) {
        var self = this;
        this.$firebase.auth
          .sendPasswordResetEmail(self.email)
          .then(function() {
            // Email senself.
            //console.log("email sent");
            self.emailSent = true;
            self.snackbar = new SNACKBAR(
              true,
              "success",
              '"A link to reset your password has been sent to your email address. Check your inbox."',
              1500
            );
            self.snackbarKey++;
          })
          .catch(function(error) {
            //console.log("err: " + error);
            self.emailSent = false;
            self.snackbar = new SNACKBAR(true, "error", error, 1500); // update snack bar with error

            self.snackbarKey++;
          });
      } else {
        self.snackbar = new SNACKBAR(
          true,
          'errorSnackbar',
          "Fill out your email correctly field in order to proceed.",
          1500
        ); // update snack bar with error

        self.snackbarKey++;
      }
    },
    goToLogin(){
       this.$router.push("/access").catch((error) => {
        console.log(error.message);
      });
    }
  },
};
</script>
