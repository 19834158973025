//global mixins

export const mixins = {
    data: () => ({
        testMixinData: 'Horray Mixin is working',
        dbResult: '',
        offline: false,
            refreshing: false,
            registration: null,
            updateExists: false,
    }),
    watch: {
        result: {
            handler() {
                // console.log(JSON.stringify(this.result, null, 2));
            },
            deep: true
        }
    },
    computed: {
        filteredEventsAdmin() {
            var events = this.$sortBy(this.events, 'eventDate'); // sort by eventDate
            return events;
        },
        filteredUsersAdmin() {
            var users = this.$sortBy(this.users, 'firstName'); // sort by eventDate
            return users;
        },
        filteredEvents() {
            var dateYesterday = this.$moment().endOf('day').subtract(1, 'day').format('x'); 
            var events = this.$sortBy(this.events, 'eventDate'); // sort by eventDate
            events = events.filter((event) => {
                //console.log('event date: ' + event.eventDate + '\nyesterday: ' + dateYesterday)
                //console.log(event.eventDate > dateYesterday)
                return event.eventDate > dateYesterday
            });
            return events;
        },
        computedHeaders() {
            var headers = this.headers.filter(header => header.hidden === false)
            if (this.$vuetify.breakpoint.name === 'xs') {
                headers = headers.filter(header => header.hide !== true) 
            }
            return headers 
        },
    },
    methods: {
        printPass() {
            window.print();
        },

        // method to filter Lookup Values based on their table name, also returns alphabetically
        filteredLookupValues: function(lookupTable) {
            var lookupValues = this.lookupValues.filter(items => items.lookupTable === lookupTable);
            lookupValues = this.$sortBy(lookupValues, 'text');
            return lookupValues
        },

        formatDatetime: function(datetime, formatIn, formatOut) {
            if (datetime === null || datetime === undefined || datetime === '') {
                return '';
            } else {
                return this.$moment(datetime, formatIn).format(formatOut)
            }
        },


        //lookup venue
        lookupVenue: function(id, key) {
            var index = this.venues.findIndex(venue => venue.id === id)
            if (index !== -1) {
                return this.venues[index][key];
            } else {
                return 'UNKNOWN VENUE'
            }
        },

        // lookup pass
        lookupPass: function(id, key) {
            var index = this.passes.findIndex(pass => pass.eventId === id)
            if (index !== -1) {
                return this.passes[index][key];
            } else {
                return 'NO PASS'
            }
        },

        //lookup event
        lookupEvent: function(id, key) {
            var index = this.events.findIndex(event => event.id === id)
            if (index !== -1) {
                return this.events[index][key];
            } else {
                return 'UNKNOWN EVENT'
            }
        },

        //lookup user
        lookupUser: function(id, key) {
            var index = this.users.findIndex(user => user.id === id)
            if (index !== -1) {
                return this.users[index][key];
            } else {
                return 'UNKNOWN USER'
            }
        },

        //lookup user
        lookupQuestion: function(id) {
            var index = this.questions.findIndex(question => question.id === id)
            if (index !== -1) {
                return this.questions[index];
            } else {
                return 'UNKNOWN USER'
            }
        },
        
        //lookup question
        lookupUserDetails: function(id) {
            var index = this.users.findIndex(user => user.id === id)
            if (index !== -1) {
                return this.users[index];
            } else {
                return 'UNKNOWN USER'
            }
        },

        // snackbars

        //firebase crud methods
        // create document
        createDocument(collection, document) {
            var t = this;
            document = JSON.parse(JSON.stringify(document))
            return new Promise(function(resolve) {
                document.createdUserId = t.$store.getters.currUser.userID; // add userId to document
                document.createdDatetime = t.$moment().format('x'); // add created timestamp to document
                document.updatedUserId = t.$store.getters.currUser.userID; // add userId to document
                document.updatedDatetime = t.$moment().format('x'); // add created timestamp to document

                // console.log(JSON.stringify(document, null, 2))
                t.$firebase.db.collection(collection).doc().set(document)           
                    .then(() => {
                        resolve ({ code: 1, message: 'Document Written Successfully to Database', data: null, error: null })
                    })
                    .catch((error) => {
                        resolve ({ code: -1, message: 'Error Writing Document to Database', data: null, error: error })
                    });
            })
        },
        // read document by doc id
        readDocumentById(collection, id) {
            // console.log('collection = ' + collection);
            // console.log('id = ' + id)
            var t = this;
            return new Promise(function(resolve) {

                t.$firebase.db.collection(collection).doc(id).onSnapshot((doc) => {
                    var Data = {}
                    // console.log('doc' + doc.data());
                    Data = doc.data();
                    Data.id = doc.id;
                    resolve (Data);
                })      
            })
            
        },
        // read documents
        readDocuments(collection, dataObject) {
            var t = this;
                t.$firebase.db.collection(collection).onSnapshot((querySnapshot) => {
                    var data = [];
                    var Data = {};
                    querySnapshot.forEach((doc) => {
                        Data = doc.data();
                        Data.id = doc.id;
                        data.push(Data)
                    });
                    this[dataObject] = data;
                })      
            
        },
        // read documents by key value pair
        readDocumentsByKeyValue(collection, dataObject, key, value) {
            var t = this;
                t.$firebase.db.collection(collection).where(key, "==", value).onSnapshot((querySnapshot) => {
                    var data = [];
                    var Data = {};
                    querySnapshot.forEach((doc) => {
                        Data = doc.data();
                        Data.id = doc.id;
                        data.push(Data)
                    });
                    this[dataObject] = data;
                })      
            
        },
        // read document by key value pair
        readDocumentByKeyValue(collection, userId, key, value) {
            var t = this;
            return new Promise(function(resolve) {
                t.$firebase.db.collection(collection).where('userId', "==", userId).where(key, "==", value).onSnapshot((querySnapshot) => {
                    var data = [];
                    var Data = {};
                    querySnapshot.forEach((doc) => {
                        Data = doc.data();
                        Data.id = doc.id;
                        data.push(Data)
                    });
                    resolve (data)
                })      
            })
            
        },        
        // update
        // create document
        updateDocument(collection, document) {
            var t = this;
            document = JSON.parse(JSON.stringify(document))
            return new Promise(function(resolve) {
                document.updatedUserId = t.$store.getters.currUser.userID; // add userId to document
                document.updatedDatetime = t.$moment().format('x'); // add created timestamp to document
                // console.log ( "id " + document.id);
                // console.log ( "DOCU " + JSON.stringify(document,null,2));
                t.$firebase.db.collection(collection).doc(document.id).set(document)           
                    .then(() => {
                        resolve ({ code: 1, message: 'Document Updated Successfully in Database', data: null, error: null })
                    })
                    .catch((error) => {
                        resolve ({ code: -1, message: 'Error Updating Document in Database', data: null, error: error })
                    });
            })
        },

        // delete
        deleteDocument(collection, id) {
            var t = this;
            return new Promise(function(resolve) {
                t.$firebase.db.collection(collection).doc(id).delete()       
                .then(() => {
                    resolve ({ code: 1, message: 'Document Deleted from Database', data: null, error: null })
                })
                .catch((error) => {
                    resolve ({ code: -1, message: 'Error Deleting Document from Database', data: null, error: error })
                });
            })
        },

        // service worker functions
        start() {
            //window.location.reload()
        },
        // Store the SW registration so we can send it a message
        // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        updateAvailable(event) {
            this.registration = event.detail
            this.updateExists = true
        },
        refreshApp() {
            // console.log('refreshing happening now')
            this.updateExists = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }

    },
    created() {
        document.addEventListener('swUpdated', this.updateAvailable, { once: true })
        // This prevents Multiple Refreshes
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            // We'll also need to add 'refreshing' to our data originally set to false.
            if (this.refreshing) return
            this.refreshing = true
            // Here the actual reload of the page occurs
            window.location.reload() // auto refreshing
        });
    }
  }