<template>
  <div id="footer">
    <v-row class="d-flex justify-center  align-center no-gutters" >
      <v-col
        cols="12"
        xs="12"
        class="text-right d-flex justify-center justify-sm-center align-center"
      >
        <div class="px-3 my-2 ">
          <a href="http://www.vindico.net" target="_new"
            ><v-img
              alt="Vue logo"
              src="../assets/vlogo.png"
              max-width="120"
              contain
            ></v-img
          ></a>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center  align-center no-gutters">
      <v-col
        cols="12"
        xs="12"
        class="text-right d-flex justify-center justify-sm-center align-center">
        &copy; Copyright {{ new Date().getFullYear() }}
        <a
          href="https://www.vindico.net/"
          target="_blank"
          style="color: var(--v-primary-base) !important; text-decoration: none;"
          ><b>Vindico</b></a
        >
      </v-col>
    </v-row>
    <v-dialog style="z-index: 3000 !important;"
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="secondary heading5 black--text">
          Privacy and Terms & Conditions
          <v-spacer></v-spacer>

          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-toolbar>

        <PrivacyTermsConditions />
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  class="secondary buttonmin white--text" @click="privacyDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrivacyTermsConditions from "./termsAndConditions.vue";

export default {
  name: "Footer",
  components: {
    PrivacyTermsConditions,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style>
#footer {
    position: center ;
    bottom: 0;
    width: 100%;
}
</style>
