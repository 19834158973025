<template>
  <v-app class="background">
    <v-banner v-if="$store.getters.currUser !== null" width="100%" height="150" max-height="110" app sticky class="noshaddow noprint background pa-4" style="border-bottom: 4px solid #BDAA5E !important; z-index: 2000 !important;">
      <appheader app sticky class="noprint" />
      <!-- <v-divider stlye="width: 100% !important;" class="my-5 secondary divider"></v-divider> -->
    </v-banner>
    <v-banner v-if="$store.getters.currUser === null" width="100%" height="150" max-height="110" app sticky class="noshaddow noprint noborder background pa-4" style="border-bottom: 4px solid #BDAA5E !important; z-index: 2000 !important;">
      <appheaderloggedout app sticky class="noshaddow noprint" />

    </v-banner>

    <v-banner height="48px" app sticky class="secondary d-flex align-center noprint"  v-if="updateExists" style="width: 100% !important; margin-top: 100px !important; position: fixed !important; z-index: 1000 !important;">
            <v-row no-gutters class="pa-2 ma-0 d-flex justify-center align-center" >
             <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pa-0 ma-0 d-flex align-center justify-center">
               <div class="px-4 primary--text">App Update Available</div>   
               <div class="px-4"><v-btn small @click="refreshApp" dark class="animate__animated animate__pulse animate__infinite"><span class="white--text">Update now</span></v-btn></div>
             </v-col>
           </v-row>
    </v-banner>
    <v-banner v-if="$store.state.showAdminDashboard" height="48px" width="100%" app sticky style="margin-top: 110px !important; position: fixed !important;" >
      <adminmenu />
    </v-banner>
    <v-main class="appbackground" :class="$store.state.showAdminDashboard ? 'addmargin' : ''">
      <v-container class="ma-0 pa-0" fluid>
        <transition
            name="custom-classes-transition"
            enter-active-class="animate__animated animate__fadeIn animate__faster"
            leave-active-class="animate__animated animate__fadeOut animate__faster"
            mode="out-in"
          >
            <router-view></router-view>
        </transition>
      </v-container>

    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      currentUser: []
    }
  },
  watch: {
    currentUser: {
      handler: function() {
        this.$store.commit("setUser", this.currentUser);
        // console.log(
        //   "currentUser: " + JSON.stringify(this.currentUser, null, 2)
        // );
      },
      deep: true,
    },
  },
  async created() {
  var t = this;
    this.$firebase.auth.onAuthStateChanged((user) => {
      if(user != null){
        t.$firebase.db
          .collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.key = doc.id;
              var document = doc.data();
              document.id = doc.id;
              this.currentUser = document;
              //console.log('doc exists')
            } else {
               t.$router.push("/").catch(error => {
                console.log(error.message)
              });
              // console.log ("The current user is empty");
              // alert("No such document!");
            }
          });
      }
        
    });
  },
}
</script>

<style>
.buttonmin {
  min-width: 150px !important;
}

.v-banner__content {
  padding: 0px !important;
  margin: 0px !important;
}

.v-banner__wrapper {
  padding: 0px !important;
  margin: 0px !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Roboto Condensed' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 16px;
}

.v-application .v-application--wrap * {
  font-family: 'Roboto Condensed' !important;
}

.v-btn__content {
  font-size: 16px;
  color: black !important;

}
.borderred { border: 2px red solid; }
.borderblue { border: 2px blue solid; }
.bordergreen { border: 2px green solid; }

.boxred { background-color: rgba(255, 0, 0, 0.2); }
.boxblue { background-color: rgba(0, 0, 255, 0.2); }
.boxgreen { background-color: rgba(0, 255, 0, 0.2); }



.bottom {
   position: fixed;
   bottom: 0px;
}

  .heading1 { letter-spacing: -.015625em; font-size: 6rem; font-weight: 300; }
  .heading2 { letter-spacing: -.0083333333em; font-size: 3.75rem; font-weight: 300; }
  .heading3 { font-size: 3rem; font-weight: 400; }
  .heading4 { letter-spacing: .0073529412em; font-size: 2.125rem; font-weight: 400; }
  .heading5 { font-size: 1.5rem; font-weight: 300; }
  .heading6 { font-size: 1.25rem; font-weight: 500; }
  .subtitle1 { letter-spacing: .009375rem; font-size: 1rem; font-weight: 400; }
  .subtitle2 { letter-spacing: .0071428571em; font-size: 0.875rem; font-weight: 500; }
  .body1 { letter-spacing: .03125em; font-size: 1rem; font-weight: 300; }


  .body2 { letter-spacing: .0178571429em; font-size: 0.875rem; font-weight: 400; }
  .button { letter-spacing: .0892857143em; font-size: 0.875rem; font-weight: 500; }
  .caption { letter-spacing: .0333333333em; font-size: 0.75rem; font-weight: 400; }
  .overline { letter-spacing: .1666666667em; font-size: 0.75rem; font-weight: 500; }
 
.card {
    border-radius: 5px;
    background-color:rgba(0, 0, 0, 0.3);
  }
.pointer {
  cursor: pointer !important;
}
.notallowed {
  cursor: not-allowed;
}
.addmargin {
  margin-top: 48px !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
  
  .noborder {
    border: 0px !important;
  }
  .noshaddow {
    box-shadow: 0px !important;
  }
  .v-banner__wrapper {
    border: 0px !important;
  }

  .appbackground {
    background-color: #343434; /*#D8252E/*  /* 343434 */
  }

@media print {
    body {
      -webkit-print-color-adjust: exact !important;
      background-color: #fff !important;
       -webkit-print-color-adjust:  #fff !important; 
    }
    .noprint {
      display:none; 
    }
    .appbackground {
      background-color: #fff !important;
       -webkit-print-color-adjust:  #fff !important; 
    }
  
  }

</style>