<template>
  <v-card-text class="background mt-4">
    <p class="subtitle1">
      <strong class="white--text  "
        >Click on the following links to read the terms and conditions.
      </strong>
    </p>
    <v-row class="background">
      <v-col cols="12" xs="12" sm="6" md="3">
        <v-btn large block class="secondary" href="https://community.wru.wales/the-wru/legal/gender-pay-gap-2018/" target="_blank">Gender Pay Gap</v-btn>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="3">
        <v-btn large block class="secondary" href="https://community.wru.wales/the-wru/legal/equality/" target="_blank">Equality</v-btn>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="3">
        <v-btn large block class="secondary" href="https://community.wru.wales/the-wru/legal/legal-and-privacy/" target="_blank">Legal and Privacy</v-btn>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="3">
        <v-btn large block class="secondary" href="https://community.wru.wales/the-wru/legal/wru-gbe-criteria-2019-2020/" target="_blank">Principality Stadium Access GBE Criteria 2020-2021</v-btn>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="3">
        <v-btn large block class="secondary" href="https://community.wru.wales/the-wru/legal/modern-slavery-statement/" target="_blank">Modern Slavery Statement</v-btn>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="3">
        <v-btn large block class="secondary" href="https://community.wru.wales/the-wru/legal/website-tcs/" target="_blank">Website T&C</v-btn>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "PrivacyTermsConditions",
};
</script>