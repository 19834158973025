<template>
  <v-layout align-content-center justify-center>
    <v-container
      width="100%"
      flat
      :class="{
        'pa-4 mt-4': $vuetify.breakpoint.smAndUp,
        'pa-4 mt-4 mx-4': $vuetify.breakpoint.xs,
      }"
    >
      <div class="text-center">
        <v-icon color="secondary" class="vicon-noaccess"
          >icons8-no-access</v-icon
        >
        <v-divider class="primary mt-4"></v-divider>
        <p class="font-weight-bold heading2 secondary--text my-5">Access denied</p>
        <span class="heading6 my-5"
          >You are not authorized to access this page. Please go back to return
          to home.</span
        >
      </div>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: "RestrictedPage",
  data() {
    return {};
  },
  computed: {},
  methods: {
    goToLink: function() {
      this.$router.push("/").catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>

<style>
.vicon-noaccess{
    font-size:130px !important;
}
</style>
