import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import moment from 'moment';
Vue.prototype.$moment = moment;
import './assets/accesspass/css/styles.min.css';
import '../node_modules/animate.css/animate.min.css';

import appHeaderLoggedOut from './components/appHeaderLoggedOut.vue';
Vue.component('appheaderloggedout', appHeaderLoggedOut);


import appHeader from './components/appHeader.vue';
Vue.component('appheader', appHeader);
import appFooter from './components/appFooter.vue';
Vue.component('appfooter', appFooter);
import appSnackbar from './components/appSnackbar.vue';
Vue.component('appsnackbar', appSnackbar);

import adminMenu from './components/adminMenu.vue';
Vue.component('adminmenu', adminMenu);

import { sortBy } from 'lodash-es';
Vue.prototype.$sortBy = sortBy;

import { VueMasonryPlugin } from "vue-masonry";
Vue.use(VueMasonryPlugin);

//firebase
const firebase = require("./firebase/firebase-config.js");
Vue.prototype.$firebase = firebase;


import { mixins } from "./mixins"; // Import Mixings - Regulary Used Scripts Accross All Components
import './registerServiceWorker'
import VueOffline from 'vue-offline'

Vue.use(VueOffline, {
  mixin: true
})

Vue.mixin(mixins) // Register Mixings

Vue.config.productionTip = true

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
