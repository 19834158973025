<template>

  <v-row class="justify-center darkgrey" no-gutters >
    <v-col cols="12" xs="12" sm="11" md="11" lg="10" xl="10">
        <v-row class="justify-left darkgrey" no-gutters>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-tabs
                  background-color="darkgrey"
                  center-active
                  dark
                  ma-0
                  show-arrows
                >
                  <v-tab to="/admin/passes">Passes</v-tab>
                  <v-tab to="/admin/events">Events</v-tab>
                  <v-tab to="/admin/venues">Venues</v-tab>
                  <v-tab to="/admin/questions">Questions</v-tab>
                  <v-tab to="/admin/users">Users</v-tab>
                  <v-tab v-if="this.$store.state.currUser !== null && this.$store.state.currUser.userLevel > 100" to="/admin/lookuptables">Lookup Tables</v-tab>
                  <v-tab v-if="this.$store.state.currUser !== null && this.$store.state.currUser.userLevel > 100" to="/admin/lookupvalues">Lookup Values</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {  
    data(){
      return {
        
      }
    },
    mounted() {
      // console.log('this.$route.path = ' + this.$route.path)
    },
    created() {
      if (this.$store.getters.currUser !== null) {
      // console.log(this.$store.getters.currUser)
      // console.log('should be null')
      // console.log('this.$store.getters.currUser.userType ' + this.$store.getters.currUser.userType)
      // console.log('this.$router.path = ' + this.$route.path)
      // console.log('includes = ' + this.$route.path.includes('/admin'))
      // console.log('this.$store.state.showAdminDashboard = ' + this.$store.state.showAdminDashboard)
        if (this.$route.path.includes('/admin') && this.$store.getters.currUser.userType === 'admin') {
          this.$store.commit("setShowAdminDashboard", true);
        }
      } else {
        // console.log('here')
        this.$store.commit("setShowAdminDashboard", false);
      }
    },
  }
</script>

<style scoped>
.backgroundmenu {
    background: #171717;
}
</style>