import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    options: {
        customProperties: true
      },
    theme: {
        themes: {
          light: {
            // primary: '#D8252E',
            // secondary: '#ffffff', //#68AEE0',
            // accent: '#007d32',
            // background: '#D8252E', //#343434
            // darkgrey: '#1E1E1E',
            // greytext: "#424242",
            // lightgrey: "#AEAEAE",
            // success: '#0A8137',
            // warning: '#ffffff',
            // error: '#ffffff',
            errorSnackbar: '#ffffff',
            primary: '#CB0F23',
            secondary: '#BDAA5E',
            background: '#343434',
            darkgrey: '#1E1E1E',
            lightgrey: "AEAEAE",
            success: '#0A8137',
            warning: '#CB0F23',
            error: '#CB0F23'
          },
        },
    },
    icons: {
        iconfont: 'mdi' || 'icons8', // default - only for display purposes
    },
});

export default vuetify;
