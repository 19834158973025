import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user.js";

Vue.use(Vuex);

//auth
import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  state: {
    currUser: null,
    showAdminDashboard: false,
    userEmail: null
  },
  plugins: [createPersistedState()],
  getters: {
    currUser(state) {
      // console.log("")
      return state.currUser;
    },
    getRegisterUserEmail(state) {
      // console.log("")
      return state.userEmail;
    },
  },
  mutations: {
    setUser(state, payload) {
      state.currUser = payload;
      //console.log("setUser: " + JSON.stringify(state.currUser))
    },
    setShowAdminDashboard(state, payload) {
      state.showAdminDashboard = payload;
    },
    setRegisterUserEmail(state, payload) {
      state.userEmail = payload;
    }
  },
  actions: {},
  modules: {
    user: user,
  },
});
