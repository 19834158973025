import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCNJshRYgEKQK4YCP8ishtxFOVw0B0tK_s",
    authDomain: "wru-swapp-hotels-app.firebaseapp.com",
    projectId: "wru-swapp-hotels-app",
    storageBucket: "wru-swapp-hotels-app.appspot.com",
    messagingSenderId: "925585740949",
    appId: "1:925585740949:web:6a096355a9ff8740c3379c",
    measurementId: "G-EZXCFBVRZT"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;

export { db, storage, auth, currentUser };
