<template>
  <div class="home">
    <!-- // * Start Page Welcome Message -->
    <v-row class="px-5 justify-center pt-4">
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"> 
        <div class="heading6 white--text">Making Events Safer<br /></div>
        <p class="mt-3 text-left body2">
          At the Principality Stadium Access , the wellbeing of our staff, contractors and the general public remains our top priority. <br /><br />
          In-line with Government guidelines and Principality Stadium Access Policy, we require all staff to carry out a Covid-19 self-assessment every day prior to arriving at the hotel.
        </p>
      </v-col>
    </v-row>
    <!-- start of ENTER-EMAIL-CONTAINER-->
    <v-row class="px-5 justify-center no-gutters">
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"> 
        <v-container class="pa-0 ma-0">
          <!--INSTRUCTION-TEXT-->
          <div v-if="showEmailField === true && loginAdminButton === false">
            <div class="heading6 secondary--text text-left" >Email Sign In</div>
            <div class="body2 mb-3 text-left" >Whether you are new to the ground or a returning user, please enter your email to proceed.</div>
          </div>
          <!--EMAIL-INPUT-RESPONSE-->
          <div v-if="showEmailField === false && loginAdminButton === false">
            <div class="heading6 secondary--text text-left" >Email Sign In</div>
            <div class="body2 mb-3 text-left" >{{ checkEmailOutcome }}</div>
          </div>
          <!--EMAIL-INPUT-->
          <v-form ref="email" v-on:submit.prevent="sendLink" v-model="valid" lazy-validation>
          <div class="d-flex align-start">
            <v-text-field
              v-if="showEmailField === true"
              hide-details="auto"
              v-model.trim="email"
              label="Email address"
              solo
              required
              :rules="emailRules"
             v-on:keyup.enter="sendLink()"
            >
            </v-text-field>
            <!--CHECK-EMAIL-BUTTON-->
            <v-btn
              v-if="showEmailField === true && loginAdminButton === false" @click="sendLink()" class="ml-2 secondary" large>
              Next
            </v-btn>
            </div>
          </v-form>
      
      <!--PASSWORD-INPUT-->
      <v-form ref="password" v-on:submit.prevent="loginAdmin" v-model="valid1" lazy-validation>
        <v-text-field
          v-if="showPasswordField === true"
          hide-details="auto"
          class="mt-2"
          v-model.trim="password"
          :append-icon="show ? 'icons8-eye' : 'icons8-invisible'"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          maxlength="50"
          minlength="8"
          required
          :rules="passwordRules"
          solo
          label="Password"
           v-on:keyup.enter="
              loginAdmin(); loader = 'loading'"
        >
        </v-text-field>
      </v-form>
         <p class="ml-1 mt-1" v-if="loginAdminButton === true">Forgot Password? <router-link :to="{ path: 'reset-password' }" replace class="btn btn-link secondary--text">Reset password</router-link></p>
      <!--ACTION-BUTTONS-->
      <v-row class="justify-end">
        <v-col cols="12" xs="" sm="" md="" lg="" xl="" class="text-center justify-center">


        <!--LOGIN-BUTTON-->
        <v-btn
          v-if="loginAdminButton === true"  @click="loginAdmin(); loader = 'loading'" class="buttonmin mt-2 secondary" :loading="loading"
          :disabled="loading" large>
          Login 
        </v-btn>

        <!--CHECK-EMAIL-AGAIN-BUTTON-->
        <v-btn v-if="showEmailField === false && loginAdminButton === false" @click="checkEmailAgain() " class="buttonmin mt-2 secondary" large>
          Resend Invite
        </v-btn>
        </v-col>
      </v-row>
    </v-container>
      </v-col>
        </v-row>
       <!--end of ENTER-EMAIL-CONTAINER -->
   <v-row class="px-5 justify-center">
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-center"> 
        <appfooter />
      </v-col>
    </v-row>

    <!--notification-component-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
    </transition>
  </div>
</template>

<script>
import firebase from "firebase/app";

class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}
export default {
  name: "Home",
  components: {},
  data() {
    return {
      snackbarKey: 0, // notifications
      snackbar: {}, //notifications
      valid: true, // validation for email
      valid1: true, // validation for password
      email: "", //email holder
      password: "", // password holder
      showPasswordField: false, // * displays password input when true
      showEmailField: true, // * displayes email input when true
      checkEmailOutcome: "", // * displays email outcome messages to the user
      show: false, // show/hide password in input
      loginAdminButton: false, // * displays login button when true
      loader: null,
      loading: false,

      // * VALIDATION RULES
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Min 8 characters",
      ],
      
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  methods: {
    async sendLink() {
      // * CHECK THE EMAIL
      if (this.$refs.email.validate()) {
        var email = this.email.toLowerCase(); // set email to user input
        var t = this;

        // * check if user exists in the database
        await this.$firebase.db
          .collection("users")
          .where("email", "==", email)
          .get()
          .then(function(querySnapshot) {
            querySnapshot.forEach(function() {  // ! REMOVED doc from function
              t.userExists = 1; // * 1 - user was found
              
            });
            // * if the user exists
            if (t.userExists === 1) {
              // * check the sign in method associated with the account
              t.$firebase.auth.fetchSignInMethodsForEmail(email).then(function(signInMethods) {
                  // * if user signed in method using email and password
                  if (signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD ) != -1) {
                    t.checkEmailOutcome = "Enter your password bellow in order to proceed."; // * update message
                    t.showPasswordField = true; // * display password field
                    t.loginAdminButton = true; // * display login button
                  }                  
                })
                .catch(function(error) {
                  // ! check user sign in method error
                  t.showEmailField = false; // * hide email field
                  t.checkEmailOutcome = error; // * display error message
                  t.snackbar = new SNACKBAR(true,'errorSnackbar',error,1500); // update snack bar with error
                  t.snackbarKey++;
                });
            } else {
              // * if user is not found in the dabase
              t.showEmailField = false; // * hide email field
              t.$store.commit('setRegisterUserEmail', email);
              t.$router.push('/register').catch((error) => {
                 console.log(error.message);
              });
            }
          });
      } else {
        // ! did not pass validation error
        this.snackbar = new SNACKBAR(true,'errorSnackbar',"Fill out all the email address correctly in order to proceed.",1500); // update snack bar with error
        this.snackbarKey++;
      }
    },
    // * login user
    loginAdmin() {
      var t = this;
      var email = this.email.toLowerCase();
      if (this.$refs.password.validate()) {
        this.$firebase.auth
          .signInWithEmailAndPassword(email, t.password)
          .then(() => {
            // * set the current user
            ////this.$store.commit("setUser", data.user);
            // * redirect to home page
            setTimeout(function(){
              t.$router.push("/").catch(() => { // error was removed
                // console.log(error.message)
              });
            }, 1500);
            
          })
          .catch((err) => {
            // ! login error
            t.snackbar = new SNACKBAR(true,'errorSnackbar',err.message,1500); // update snack bar with error
            t.snackbarKey++;
          });
      } else {
        // ! validation error
        this.snackbar = new SNACKBAR(true,'errorSnackbar',"The password you entered is not valid.",1500); // update snack bar with error
        this.snackbarKey++;
      }
    },
    // * re-check email
    checkEmailAgain(){
      this.showEmailField = true; // * show email field
      this.loginAdminButton = false;  // * hide login button
      this.email = ''; // * reset email input
    }
  },
  mounted(){
    var t = this;
    if(this.$store.getters.currUser != null){
       t.$router.push("/").catch(() => { // ! err was removed
              // console.log(err);
            });
    }
  }
 
};
</script>

<style>
.divider {
  min-height: 3px !important;
  border-radius: 10px !important;
}
</style>
