const user = {
    namespaced: true,
    state: {
      active: {
        
      }
    },
    mutations: {
      setUserProfile(state, data) {
        state.active = data;
      }
    }
  };
  
  export default user;
  